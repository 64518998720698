import guest0 from './guest0.jpeg';
import guest1 from './guest1.jpeg';
import guest2 from './guest2.jpeg';
import guest3 from './guest3.jpeg';
import guest4 from './guest4.jpeg';
import guest5 from './guest5.jpeg';
import guest6 from './guest6.jpeg';
import guest7 from './guest7.jpeg';

export default [guest0, guest1, guest2, guest3, guest4, guest5, guest6, guest7];
