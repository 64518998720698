import photo_0 from './0.png';
import photo_1 from './1.jpg';
import photo_2 from './2.jpg';
import photo_3 from './3.jpg';
import photo_4 from './4.jpg';
import photo_5 from './5.jpg';
import photo_6 from './6.jpg';
import photo_7 from './7.jpg';

export default [
    photo_0,
    photo_1,
    photo_2,
    photo_3,
    photo_4,
    photo_5,
    photo_6,
    photo_7
];
